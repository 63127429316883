import React from 'react'
import styled from 'styled-components'

const Footer = () => {
	return (
		<Info>
			<span className="copyright">Copyright © 2020 Hodstuff Games. All rights reserved.</span>
		</Info>
	)
}

const Info = styled.footer`
	display: flex;
	flex-flow: column;
	align-items: center;
	position: fixed;
	bottom: 7vh;
	
	.copyright {
		font-size: 0.7rem;
		color: rgba( 0, 0, 0, 0.4 );
		padding: 0 8vw;
		text-align: center;
	}
`

export default Footer
