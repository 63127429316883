import React from 'react'
import styled, { keyframes } from 'styled-components'
import rulesPDF from '../../static/Reign_and_Ruin_Standalone_rules.pdf'
import sellSheet from '../../static/Reign_and_Ruin_Sell_Sheet.pdf'
import PublisherBlob from './publisher_blob.svg';

const Header = () => {
	return (
		<Info>
			<h1>Welcome to Hodstuff Games!</h1>
			<ul>
				<li>
					<a href={ sellSheet } target="_blank" rel="noreferrer noopener" type="application/pdf">Reign &amp; Ruin Sellsheet</a>
				</li>
				<li>
					<a href={ rulesPDF } target="_blank" rel="noreferrer noopener" type="application/pdf">Reign & Ruin Rules</a>
				</li>
				<li>
					<a href="https://www.thegamecrafter.com/games/reign-ruin-mint-tin-edition" target="_blank" rel="noreferrer noopener">Buy Reign & Ruin at The Game Crafter</a>
				</li>
				<li><a href="mailto:contact@hodstuff.com">Get in touch</a></li>
			</ul>
		</Info>
	)
}

const bob = keyframes`
	0% {
		transform: translate(-50%, -105%) scale( 0.97, 1.03 );
	}
	
	50% {
		transform: translate(-50%, -95%) scale( 1.03, 0.97 );
	}
	
	100% {
		transform: translate(-50%, -105%) scale( 0.97, 1.03 );
	}
`

const Info = styled.header`
	display: flex;
	flex-flow: column;
	align-items: center;
	position: relative;
	top: 5vh;

	h1 {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
	}
	
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin-bottom: 20px;
		padding: 0 8vw;
		
		li {
			display: flex;
			justify-content: center;
			align-items: center;
		
			padding: 0 10px;
			margin-top: 8px;
			border-right: 1px solid #ff0055;
			
			text-align: center;
			
			@media screen and (max-width: 640px) {
				font-size: 4vmin;
			}
			
			@media screen and (max-width: 724px) and (max-aspect-ratio: 1/1) {
				border: none;
			}
			
			&:last-child {
				border-right: none;
			}
			
			a.publisher {
				&:before {
					content: "";
					position: absolute;
					display: block;
					top: 0;
					left: 50%;
					width: 200px;
					height: 100px;
					background-image: url("${ PublisherBlob }");
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					
					transform: translate(-50%,-105%);
					
					animation: ${ bob } 1.5s infinite cubic-bezier(0.445, 0.050, 0.550, 0.950);
					
					@media screen and (max-width: 1023px) {
						display: none;
					}
				}
			}
		}
		
		@media screen and (max-width: 724px) and (max-aspect-ratio: 1/1) {
			flex-flow: column;
		}
	}
	
	a {
		position: relative;
		color: #222;
		text-decoration: none;
		transition: color 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000);
		
		&:hover {
			color: #ff0055;
		}
		
	}
	
	.copyright {
		font-size: 0.7rem;
		color: rgba( 0, 0, 0, 0.4 );
		padding: 0 8vw;
		text-align: center;
	}
`

export default Header
