import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

import LogoBG from './images/LogoBG.svg'
import LogoIcon from './images/LogoIcon.svg'
import LogoText from './images/LogoText.svg'

const perspectiveCalc = ( x, y ) => [ -( y - window.innerHeight / 2 ) / 20, ( x - window.innerWidth / 2 ) / 20, 1 ]
const rotationTransform = ( x, y, s ) => `translate3d(0, 0, 1px) rotateX(${ x }deg) rotateY(${ y }deg) scale(${ s })`
const translationTransform = t => `translate3d( 0, 0, ${ t }px)`

const Logo = () => {

	const [ rotation, rotationSet ] = useSpring( () => ( {
		from: {
			xys: [
				720,
				0,
				0
			]
		},
		to: {
			xys: [
				0,
				0,
				1
			]
		}, config: { mass: 7, tension: 150, friction: 40 }
	} ) )

	const [ translation, translationSet ] = useSpring( () => ( {
		t: [ 40, 0 ], config: { mass: 1, tension: 350, friction: 40 }
	} ) )

	function handleMove( { clientX: x, clientY: y } ) {
		rotationSet( { xys: perspectiveCalc( x, y ) } )
		translationSet( { t: [ 80, -50 ] } )
	}

	function handleLeave() {
		rotationSet( { xys: [ 0, 0, 1 ] } )
		translationSet( { t: [ 40, 0 ] } )
	}

	return (
		<Container style={ {
			opacity: rotation.xys.interpolate( ( x, y, s ) => s )
		} }>
			<Graphics onMouseMove={ handleMove } onMouseLeave={ handleLeave } style={ { transform: rotation.xys.interpolate( rotationTransform ) } }>
				<Background style={ { transform: translation.t.interpolate( ( t1, t2 ) => translationTransform( t2 ) ) } } />
				<Icon style={ { transform: translation.t.interpolate( translationTransform ) } } />
			</Graphics>
			<Text />
		</Container>
	)
}

const Container = styled( animated.div )`
	display: flex;
	justify-content: center;
	align-items: center;
	
	overflow: visible;
	position: absolute;
	top: 50vh;
	left: 50%;
	will-change: filter;
	overflow: visible;
	
	perspective-origin: center;
	perspective: 800px;
	transform-style: preserve-3d;
	transition: height 0.5s ease-out;
	
	@media screen and (min-aspect-ratio: 71/40) and ( max-height: 640px ) {
		//height: 60%;
	}

	@media screen and ( min-width: 767px ) {
		top: 40vh;
	}
`

const Graphics = styled( animated.div )`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	
	will-change: transform;
	perspective-origin: center;
	transform-style: preserve-3d;
	transform: translate3d(0,0,1px);
`

const Background = styled( animated.div )`
	position: absolute;
	min-width: 10ch;
	min-height: 10ch;
	width: 50vmin;
	height: 50vmin;
	max-width: 100ch;
	max-height: 100ch;
	border-radius: 50%;
  	background-image: url(${ LogoBG });
  	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
`

const Icon = styled( animated.div )`
	position: absolute;
	border-radius: 50%;
	background-size: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	will-change: transform;
	min-width: 10ch;
	min-height: 10ch;
	width: 36vmin;
	height: 36vmin;
	max-width: 100ch;
	max-height: 100ch;
  	background-image: url(${ LogoIcon });
`
const Text = styled.div`
	position: absolute;
	top: calc( 50% + 30vmin );
	min-width: 10ch;
	width:  50vmin;
	height:  calc( 50vmin * 0.11 );
	max-width: 100ch;
	background-image: url(${ LogoText });
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
`

export default Logo
