import 'normalize.css'
import React from 'react'
import CardImage from '../../static/twitterCardImage.jpeg'
import '../common/base.css'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Logo from '../components/Logo/Logo'
import SEO from '../components/seo'

const App = () => {

	return (
		<>
			<SEO title="Hodstuff Games" image={ { src: CardImage, height: 500, width: 1500 } } />
			<Header />
			<Logo />
			<Footer />
		</>
	)
}

export default App
